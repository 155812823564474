@keyframes glow-animation {
  0%,
  100% {
    box-shadow: 0 0 32px #31d843; /* Green Glow */
    /* blur? */
  }
  50% {
    box-shadow: 0 0 12px #f53232; /* Red Glow */
  }
}

.glow-effect {
  animation: glow-animation 7s ease-in-out infinite;
}

::-webkit-scrollbar {
  display: none;
}
